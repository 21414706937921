define("goodcity/controllers/application", ["exports", "goodcity/config/environment"], function (exports, _goodcityConfigEnvironment) {
  exports["default"] = Ember.Controller.extend({
    cordova: Ember.inject.service(),
    subscriptions: Ember.inject.controller(),
    isMobileApp: _goodcityConfigEnvironment["default"].cordova.enabled,
    config: _goodcityConfigEnvironment["default"],

    app_id: _goodcityConfigEnvironment["default"].APP.ANDROID_APP_ID,
    ios_app_id: _goodcityConfigEnvironment["default"].APP.IOS_APP_ID,
    appTitle: _goodcityConfigEnvironment["default"].APP.TITLE,
    bannerImage: _goodcityConfigEnvironment["default"].APP.BANNER_IMAGE,

    initSubscriptions: Ember.on("init", function () {
      if (this.session.get("isLoggedIn")) {
        this.send("setSubscriptions");
      }
    }),

    supportGCLink: Ember.computed("session.language", function () {
      return this.get("session.language") === "zh-tw" ? "https://www.crossroads.org.hk/zh-hant/home/donate-funds/" : "https://www.crossroads.org.hk/home/donate-funds/";
    }),

    appVersion: Ember.computed(function () {
      return _goodcityConfigEnvironment["default"].APP.VERSION;
    }),

    actions: {
      logMeOut: function logMeOut() {
        this.session.clear(); // this should be first since it updates isLoggedIn status
        this.get("subscriptions").send("unwire");
        this.get("subscriptions").send("unloadNotifications");
        this.store.unloadAll();
        var _this = this;
        _goodcityConfigEnvironment["default"].APP.PRELOAD_TYPES.forEach(function (type) {
          _this.store.findAll(type);
        });
        this.transitionToRoute("login");
      },

      logMeIn: function logMeIn() {
        this.send("setSubscriptions");
      },

      setSubscriptions: function setSubscriptions() {
        this.get("subscriptions").send("wire");
      },

      rateApp: function rateApp() {
        if (this.get("cordova").isIOS()) {
          this.set("app_id", this.get("ios_app_id"));
        }
        LaunchReview.launch(this.get("app_id"));
      }
    }
  });
});