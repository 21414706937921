define('goodcity/routes/ggv_orders', ['exports', 'ember', 'goodcity/utils/ajax-promise'], function (exports, _ember, _goodcityUtilsAjaxPromise) {
  exports['default'] = _ember['default'].Route.extend({
    model: function model(params) {
      var _this = this;
      return new _goodcityUtilsAjaxPromise['default']("/gogovan_orders/driver_details", "GET", null, { id: params.ggv_id }).then(function (data) {
        _this.store.pushPayload(data);
        return _this.store.peekRecord('offer', data.offer.id);
      });
    },

    afterModel: function afterModel(offer) {
      var validOffer = offer.get("isScheduled") && offer.get("delivery.gogovanOrder.nonCompleted");
      if (!validOffer) {
        throw { status: 404 };
      }
    }
  });
});