define("goodcity/services/messages", ["exports", "ember", "goodcity/utils/ajax-promise"], function (exports, _ember, _goodcityUtilsAjaxPromise) {
  var getOwner = _ember["default"].getOwner;
  exports["default"] = _ember["default"].Service.extend({
    logger: _ember["default"].inject.service(),
    session: _ember["default"].inject.service(),
    store: _ember["default"].inject.service(),
    subscriptions: _ember["default"].inject.controller(),
    messageBox: _ember["default"].inject.service(),

    unreadMessageCount: 0,

    init: function init() {
      var _this = this;

      this.get("subscriptions").on("create:message", function (_ref) {
        var id = _ref.id;

        var msg = _this.get("store").peekRecord("message", id);
        if (msg.get("isUnread")) {
          _this._incrementCount();
        }
      });

      this.get("subscriptions").on("update:message", function (_ref2) {
        var id = _ref2.id;

        var msg = _this.get("store").peekRecord("message", id);
        if (msg.get("state") === "read") {
          _this._decrementCount();
        }
      });
    },

    fetchUnreadMessages: function fetchUnreadMessages(page, perPage) {
      return this.fetchMessages(page, perPage, { state: "unread" });
    },

    fetchReadMessages: function fetchReadMessages(page, perPage) {
      return this.fetchMessages(page, perPage, { state: "read" });
    },

    fetchMessages: function fetchMessages() {
      var page = arguments.length <= 0 || arguments[0] === undefined ? 1 : arguments[0];
      var perPage = arguments.length <= 1 || arguments[1] === undefined ? 25 : arguments[1];
      var opts = arguments.length <= 2 || arguments[2] === undefined ? {} : arguments[2];

      var store = this.get("store");
      return this._queryMessages(page, perPage, opts).then(function (data) {
        store.pushPayload(data);
        return data.messages.map(function (m) {
          return store.peekRecord("message", m.id);
        });
      });
    },

    fetchUnreadMessageCount: function fetchUnreadMessageCount() {
      var _this2 = this;

      return this._queryMessages(1, 1, { state: "unread", scope: "offer,item" }).then(function (data) {
        var count = data.meta && data.meta.total_count;
        _this2.set("unreadMessageCount", count || 0);
      })["catch"](function (e) {
        return _this2._onError(e);
      });
    },

    markRead: function markRead(message) {
      var _this3 = this;

      if (message.get("isUnread")) {
        var adapter = getOwner(this).lookup("adapter:application");
        var url = adapter.buildURL("message", message.id) + "/mark_read";
        adapter.ajax(url, "PUT").then(function (data) {
          delete data.message.id;
          message.setProperties(data.message);
          _this3._decrementCount();
        })["catch"](function (e) {
          return _this3._onError(e);
        });
      }
    },

    markAllRead: function markAllRead() {
      var _this4 = this;

      return (0, _goodcityUtilsAjaxPromise.AjaxBuilder)("/messages/mark_all_read").withAuth(this.get("session.authToken")).withQuery({
        scope: "offer,item"
      }).put().then(function () {
        _this4.get("store").peekAll("message").filterBy("state", "unread").forEach(function (message) {
          message.set("state", "read");
        });
        _this4.set("unreadMessageCount", 0);
      });
    },

    getMessageRoute: function getMessageRoute(isDonorApp, isPrivate, offerId, messageableType, messageableId) {
      if (isDonorApp) {
        if (messageableType === "Item") {
          return ["item.messages", offerId, messageableId];
        } else {
          return ["offer.messages", messageableId];
        }
      } else if (isPrivate) {
        if (messageableType === "Item") {
          return ["review_item.supervisor_messages", offerId, messageableId];
        } else {
          return ["offer.supervisor_messages", messageableId];
        }
      } else {
        if (messageableType === "Item") {
          return ["review_item.donor_messages", offerId, messageableId];
        } else {
          return ["offer.donor_messages", messageableId];
        }
      }
    },

    getRoute: function getRoute(message) {
      var _this5 = this;

      var isDonorApp = this.get("session.isDonorApp");

      if (!message) {
        this.get("messageBox").alert(this.get("i18n").t("404_error"), function () {
          _this5.transitionToRoute("/");
        });
      }

      var messageableType = message.get ? message.get("messageableType") : message.messageable_type;
      var messageableId = message.get ? message.get("messageableId") : message.messageable_id;
      var isPrivate = message.get ? message.get("isPrivate") : message.is_private;
      isPrivate = isPrivate ? isPrivate.toString().toLowerCase() === "true" : false;
      var offerId = messageableType === "Item" ? message.offer_id : null;

      var messageRoute = this.getMessageRoute(isDonorApp, isPrivate, offerId, messageableType, messageableId);

      return messageRoute;
    },

    _queryMessages: function _queryMessages() {
      var page = arguments.length <= 0 || arguments[0] === undefined ? 1 : arguments[0];
      var perPage = arguments.length <= 1 || arguments[1] === undefined ? 25 : arguments[1];
      var opts = arguments.length <= 2 || arguments[2] === undefined ? {} : arguments[2];
      var _opts$scope = opts.scope;
      var scope = _opts$scope === undefined ? "offer" : _opts$scope;
      var state = opts.state;

      return (0, _goodcityUtilsAjaxPromise.AjaxBuilder)("/messages").withAuth(this.get("session.authToken")).withQuery({ state: state, scope: scope }).getPage(page, perPage);
    },

    _onError: function _onError(e) {
      this.get("logger").error(e);
    },

    _incrementCount: function _incrementCount() {
      var step = arguments.length <= 0 || arguments[0] === undefined ? 1 : arguments[0];

      var count = this.get("unreadMessageCount") + step;
      if (count < 0) {
        this.set("unreadMessageCount", 0);
      } else {
        this.set("unreadMessageCount", count);
      }
    },

    _decrementCount: function _decrementCount() {
      this._incrementCount(-1);
    }
  });
});