define("goodcity/controllers/register", ["exports", "ember", "goodcity/utils/ajax-promise", "goodcity/config/environment", "ember-i18n"], function (exports, _ember, _goodcityUtilsAjaxPromise, _goodcityConfigEnvironment, _emberI18n) {
  var getOwner = _ember["default"].getOwner;
  exports["default"] = _ember["default"].Controller.extend({
    authenticateUserController: _ember["default"].inject.controller("authenticate"),
    messageBox: _ember["default"].inject.service(),
    i18n: _ember["default"].inject.service(),
    phoneNumberPlaceholder: (0, _emberI18n.translationMacro)("register.phone_number"),
    fNamePlaceholder: (0, _emberI18n.translationMacro)("register.john"),
    lNamePlaceholder: (0, _emberI18n.translationMacro)("register.doe"),

    actions: {
      registerUser: function registerUser() {
        var _this = this;

        var loadingView = getOwner(this).lookup("component:loading").append();
        var mobilePhone = _goodcityConfigEnvironment["default"].APP.HK_COUNTRY_CODE + this.get("mobilePhone");
        var firstName = this.get("firstName");
        var lastName = this.get("lastName");
        var district_id = _ember["default"].$(".district-selection").attr("selected_id") || null;
        var user_auth = {
          mobile: mobilePhone,
          first_name: firstName,
          last_name: lastName,
          address_attributes: {
            district_id: district_id,
            address_type: "profile"
          }
        };

        new _goodcityUtilsAjaxPromise["default"]("/auth/signup", "POST", null, { user_auth: user_auth }).then(function (data) {
          _this.set("session.otpAuthKey", data.otp_auth_key);
          _this.set("session.backLinkPath", "register");
          _this.setProperties({
            mobilePhone: null,
            firstName: null,
            lastName: null
          });
          _this.get("authenticateUserController").set("pinAlreadySent", true);
          _this.get("authenticateUserController").timerFunction();
          _this.transitionToRoute("/authenticate");
        })["catch"](function (xhr) {
          if (xhr.status === 422) {
            _this.get("messageBox").alert(xhr.responseJSON.errors);
          } else {
            throw xhr;
          }
        })["finally"](function () {
          return loadingView.destroy();
        });
      }
    }
  });
});