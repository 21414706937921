define("goodcity/controllers/offer/ios_notification", ["exports", "ember"], function (exports, _ember) {
  exports["default"] = _ember["default"].Controller.extend({
    cordova: _ember["default"].inject.service(),
    displayUserPrompt: false,
    timer: null,
    _currentFunction: null,

    pollingFunction: function pollingFunction() {
      var _this = this;

      var onEnabled = function onEnabled() {
        _this.set("displayUserPrompt", false);
        _this.stop();
        _this.transitionToRoute("offer.submit");
      };

      var onDisabled = function onDisabled() {
        _this.set("displayUserPrompt", true);
      };

      this.get("cordova").verifyIosNotificationSetting(onEnabled, onDisabled);
    },

    start: function start(context, pollingFunction) {
      this.set("_currentFunction", this._schedule(context, pollingFunction));
    },

    stop: function stop() {
      _ember["default"].run.cancel(this.get("_currentFunction"));
    },

    _schedule: function _schedule(context, func) {
      return _ember["default"].run.later(this, function () {
        this.set("_currentFunction", this._schedule(context, func));
        func.apply(context);
      }, 1000);
    },

    actions: {
      sendPushNotification: function sendPushNotification() {
        this.get("cordova").initiatePushNotifications();
        this.start(this, this.pollingFunction);
      },

      closeDialog: function closeDialog() {
        this.stop();
      },

      openSettings: function openSettings() {
        window.cordova.plugins.settings.open("notification_id", function () {
          console.log("opened settings");
        }, function () {
          console.log("error opened settings");
        });
      }
    }
  });
});