define("goodcity/mixins/async", ["exports", "ember"], function (exports, _ember) {
  var getOwner = _ember["default"].getOwner;

  var getString = function getString(obj, path) {
    var keys = path.split(".");
    var val = undefined;

    while (keys.length) {
      val = obj[keys.shift()];
    }
    return val && typeof val === "string" ? val : null;
  };

  /**
   * AsyncMixin
   *
   * @description utilities to run asynchronous tasks with spinner and error management features
   * @mixin AsyncMixin
   *
   **/

  /**
   * @enum {number}
   * @readonly
   * @memberof AsyncMixin
   * @static
   */
  var ERROR_STRATEGIES = {
    /** Will ignore errors */
    IGNORE: 1,
    /** Will display the error message in a modal */
    MODAL: 2,
    /** Will let the error go through */
    RAISE: 3,
    /** Will push the error to rollbar */
    ROLLBAR: 4
  };

  exports.ERROR_STRATEGIES = ERROR_STRATEGIES;
  /**
   * Presets of configs for runTask()
   *
   * @enum {number}
   * @readonly
   * @memberof AsyncMixin
   * @static
   */
  var ASYNC_BEHAVIOURS = {
    DISCREET: {
      showSpinner: false,
      errorStrategy: ERROR_STRATEGIES.ROLLBAR
    },
    LOUD: {
      showSpinner: true,
      errorStrategy: ERROR_STRATEGIES.MODAL
    },
    SILENT_DEPENDENCY: {
      showSpinner: false,
      errorStrategy: ERROR_STRATEGIES.MODAL
    }
  };

  exports.ASYNC_BEHAVIOURS = ASYNC_BEHAVIOURS;
  exports["default"] = _ember["default"].Mixin.create({
    logger: _ember["default"].inject.service(),
    messageBox: _ember["default"].inject.service(),
    i18n: _ember["default"].inject.service(),

    ERROR_STRATEGIES: ERROR_STRATEGIES,

    // ----------------------
    // Private Helpers
    // ----------------------

    __tasksCount: 0,
    __loadingView: null,
    __modalActive: false,

    __incrementTaskCount: function __incrementTaskCount() {
      var step = arguments.length <= 0 || arguments[0] === undefined ? 1 : arguments[0];

      var count = this.get("__tasksCount") + step;

      this.set("__tasksCount", count > 0 ? count : 0);

      if (this.get("hasRunningTasks")) {
        this.showLoadingSpinner();
      } else {
        this.hideLoadingSpinner();
      }
    },

    __run: function __run(task) {
      var res = typeof task === "function" ? task() : task;
      return _ember["default"].RSVP.resolve(res);
    },

    __handleError: function __handleError(err) {
      var errorStrategy = arguments.length <= 1 || arguments[1] === undefined ? ERROR_STRATEGIES.RAISE : arguments[1];

      if (errorStrategy === ERROR_STRATEGIES.RAISE) {
        return _ember["default"].RSVP.reject(err);
      }

      if (errorStrategy === ERROR_STRATEGIES.MODAL) {
        return this.showErrorPopup(err);
      }

      if (errorStrategy === ERROR_STRATEGIES.ROLLBAR) {
        var errData = this.__toErrorSummary(err);
        this.get("logger").notifyErrorCollector(errData);
        console.error(errData);
        return;
      }
    },

    __toErrorSummary: function __toErrorSummary(error) {
      if (typeof error === "string") {
        return { message: error, details: {} };
      }

      return {
        message: this.__toErrorMessage(error),
        details: error instanceof Error ? { stack: error.stack } : error
      };
    },

    __toErrorMessage: function __toErrorMessage(reason) {
      var defaultMessage = this.get("i18n").t("unexpected_error");

      if (reason && reason.responseJSON) {
        reason = reason.responseJSON;
      }

      if (typeof reason === "string") {
        return reason;
      }

      return getString(reason, "error") || getString(reason, "errors[0].message") || getString(reason, "errors[0].detail.message") || getString(reason, "errors[0].title") || getString(reason, "errors[0]") || getString(reason, "message") || getString(reason, "reason") || defaultMessage;
    },

    // ----------------------
    // Mixin computed props
    // ----------------------

    hasRunningTasks: _ember["default"].computed("__tasksCount", function () {
      return this.get("__tasksCount") > 0;
    }),

    // ----------------------
    // Mixin api
    // ----------------------

    /**
     * Runs the asynchronous task, showing and hiding loading spinners accordingly
     *
     * @memberof AsyncMixin
     * @instance
     * @param {Promise|Function} task the job to run
     * @param {number} [opts|errorStrategy] an indicator of how to handle the error
     */
    runTask: function runTask(task) {
      var _this = this;

      var opts = arguments.length <= 1 || arguments[1] === undefined ? {} : arguments[1];

      if (typeof opts === "number") {
        opts = { errorStrategy: opts };
      }

      var _opts = opts;
      var errorStrategy = _opts.errorStrategy;
      var _opts$showSpinner = _opts.showSpinner;
      var showSpinner = _opts$showSpinner === undefined ? true : _opts$showSpinner;

      this.__incrementTaskCount(showSpinner ? 1 : 0);

      return _ember["default"].RSVP.Promise.resolve(this.__run(task))["catch"](function (err) {
        return _this.__handleError(err, errorStrategy);
      })["finally"](function () {
        _this.__incrementTaskCount(showSpinner ? -1 : 0);
      });
    },

    showLoadingSpinner: function showLoadingSpinner() {
      var _this2 = this;

      _ember["default"].run(function () {
        if (!_this2.__loadingView && !_ember["default"].testing) {
          _this2.__loadingView = getOwner(_this2).factoryFor("component:loading").create().append();
        }
      });
    },

    hideLoadingSpinner: function hideLoadingSpinner() {
      var _this3 = this;

      _ember["default"].run(function () {
        if (_this3.__loadingView && !_ember["default"].testing) {
          _this3.__loadingView.destroy();
          _this3.__loadingView = null;
        }
      });
    },

    showErrorPopup: function showErrorPopup(reason) {
      var _this4 = this;

      this.get("logger").error(reason);

      if (this.get("__modalActive")) {
        return;
      }

      this.set("__modalActive", true);
      this.get("messageBox").alert(this.__toErrorMessage(reason), function () {
        _this4.set("__modalActive", false);
      });
    },

    tryTranslate: function tryTranslate(str) {
      var props = arguments.length <= 1 || arguments[1] === undefined ? {} : arguments[1];

      var i18n = this.get("i18n");
      return i18n.exists(str) ? i18n.t(str, props) : str;
    },

    modalAlert: function modalAlert(key) {
      var props = arguments.length <= 1 || arguments[1] === undefined ? {} : arguments[1];

      var deferred = _ember["default"].RSVP.defer();
      var text = this.tryTranslate(key, props);

      this.get("messageBox").alert(text, function () {
        deferred.resolve(null);
      });

      return deferred.promise;
    },

    modalConfirm: function modalConfirm(bodyText) {
      var confirmText = arguments.length <= 1 || arguments[1] === undefined ? "confirm" : arguments[1];
      var cb = arguments.length <= 2 || arguments[2] === undefined ? function () {} : arguments[2];

      var deferred = _ember["default"].RSVP.defer();

      var onConfirm = function onConfirm() {
        cb();
        deferred.resolve(true);
      };

      var onCancel = function onCancel() {
        return deferred.resolve(false);
      };

      this.get("messageBox").custom(this.tryTranslate(bodyText), this.tryTranslate(confirmText), onConfirm, this.tryTranslate("cancel"), onCancel);

      return deferred.promise;
    }
  });
});