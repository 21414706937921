define("goodcity/controllers/item/edit", ["exports", "ember"], function (exports, _ember) {
  var getOwner = _ember["default"].getOwner;
  exports["default"] = _ember["default"].Controller.extend({
    offer: _ember["default"].computed.alias("model.offer"),
    i18n: _ember["default"].inject.service(),
    itemDescriptionPlaceholder: _ember["default"].computed(function () {
      return this.get("i18n").t("items.add_item.description_placeholder").string;
    }),

    isOfferReviewed: _ember["default"].computed("model", "model.state", "offer", "offer.state", function () {
      return this.get("offer.state") === "received";
    }),

    formData: _ember["default"].computed("model.{donorConditionId,donorDescription}", {
      get: function get() {
        return {
          donorConditionId: this.get("model.donorConditionId"),
          donorDescription: this.get("model.donorDescription")
        };
      },
      set: function set(key, value) {
        return {
          donorConditionId: value.get("donorConditionId"),
          donorDescription: value.get("donorDescription")
        };
      }
    }),

    actions: {
      submitItem: function submitItem() {
        var _this = this;

        if (this.get("model.state") === "draft") {
          this.set("model.state_event", "submit");
        }

        var data = this.get("formData");
        this.get("model").setProperties({
          donorDescription: data.donorDescription,
          donorCondition: this.get("store").peekRecord("donorCondition", data.donorConditionId)
        });
        var loadingView = getOwner(this).lookup("component:loading").append();

        this.get("model").save().then(function () {
          _this.set("model.state_event", null);
          _this.transitionToRoute("offer.offer_details");
        })["catch"](function (error) {
          _this.get("model").rollbackAttributes();
          throw error;
        })["finally"](function () {
          return loadingView.destroy();
        });
      },

      discardChanges: function discardChanges(item) {
        var controller = this;
        var offer = item.get("offer");
        if (item.get("state") === "draft") {
          var loadingView = getOwner(controller).lookup("component:loading").append();
          item.destroyRecord().then(function () {
            var route = offer.get("itemCount") === 0 ? "offer" : "offer.offer_details";
            controller.transitionToRoute(route);
          })["finally"](function () {
            return loadingView.destroy();
          });
        } else {
          controller.set("formData", item);
          controller.transitionToRoute("offer.offer_details");
        }
      }
    }
  });
});