define("goodcity/routes/offer/messages", ["exports", "shared-goodcity/routes/messages_base", "ember"], function (exports, _sharedGoodcityRoutesMessages_base, _ember) {
  exports["default"] = _sharedGoodcityRoutesMessages_base["default"].extend({
    model: function model() {
      return _ember["default"].RSVP.Promise.all([this.store.query("message", {
        messageable_type: "Offer",
        messageable_id: this.modelFor("offer").get("id")
      }), this.store.query("version", {
        for_offer: "true",
        item_id: this.modelFor("offer").get("id")
      })]);
    }
  });
});