define('goodcity/controllers/offer/confirm', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({

    cordova: _ember['default'].inject.service(),

    actions: {
      confirmSubmitOffer: function confirmSubmitOffer() {
        var _this = this;

        if (this.get("cordova").isIOS()) {

          var onEnabled = function onEnabled() {
            _this.transitionToRoute('offer.submit');
          };

          var onDisabled = function onDisabled() {
            _this.transitionToRoute('offer.ios_notification');
          };

          this.get("cordova").verifyIosNotificationSetting(onEnabled, onDisabled);
        } else {
          this.transitionToRoute('offer.submit');
        }
      }

    }
  });
});