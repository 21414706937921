define('goodcity/controllers/offer/cancel', ['exports', 'ember', 'goodcity/config/environment'], function (exports, _ember, _goodcityConfigEnvironment) {
  exports['default'] = _ember['default'].Controller.extend({
    offerController: _ember['default'].inject.controller('offer/offer_details'),

    canCancel: _ember['default'].computed.alias('model.delivery.gogovanOrder.isCancelled'),
    driverContact: _ember['default'].computed.alias('model.delivery.gogovanOrder.driverMobile'),
    gogovanContact: _goodcityConfigEnvironment['default'].APP.GOGOVAN_CONTACT,

    actions: {
      cancelOffer: function cancelOffer() {
        if (this.get('canCancel')) {
          this.get('offerController').send('cancelOffer', this.get('model'));
        }
      }
    }
  });
});