define('goodcity/routes/offers/new', ['exports', 'goodcity/routes/authorize'], function (exports, _goodcityRoutesAuthorize) {
  exports['default'] = _goodcityRoutesAuthorize['default'].extend({
    model: function model() {
      var _this = this;

      var offer = this.store.peekAll('offer').filterBy('itemCount', 0).filterBy('state', 'draft').get('firstObject');
      if (offer) {
        return this.transitionTo('offer', offer.id);
      }

      offer = this.store.createRecord('offer');
      offer.save().then(function () {
        return _this.transitionTo('offer', offer.id);
      })['catch'](function (error) {
        _this.transitionTo('offers');
        throw error;
      });
    }
  });
});