define("goodcity/controllers/account", ["exports", "ember", "goodcity/mixins/async"], function (exports, _ember, _goodcityMixinsAsync) {
  exports["default"] = _ember["default"].Controller.extend(_goodcityMixinsAsync["default"], {
    messageBox: _ember["default"].inject.service(),
    application: _ember["default"].inject.controller(),

    isEditing: false,

    user: _ember["default"].computed.alias("model.user"),

    fields: [{
      key: "firstName",
      editable: true
    }, {
      key: "lastName",
      editable: true
    }, {
      key: "mobile",
      editable: false
    }],

    actions: {
      startEditing: function startEditing() {
        this.set("isEditing", true);
      },

      cancelEdit: function cancelEdit() {
        this.get("user").rollbackAttributes();
        this.set("isEditing", false);
      },

      saveEdit: function saveEdit() {
        var _this = this;

        var fields = this.get("fields");
        var user = this.get("user");

        var _iteratorNormalCompletion = true;
        var _didIteratorError = false;
        var _iteratorError = undefined;

        try {
          for (var _iterator = fields[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
            var field = _step.value;
            var key = field.key;

            if (!user.get(key)) {
              return; // we won't save anything blank
            }
          }
        } catch (err) {
          _didIteratorError = true;
          _iteratorError = err;
        } finally {
          try {
            if (!_iteratorNormalCompletion && _iterator["return"]) {
              _iterator["return"]();
            }
          } finally {
            if (_didIteratorError) {
              throw _iteratorError;
            }
          }
        }

        return this.runTask(function () {
          return user.save().then(function () {
            _this.set("isEditing", false);
          });
        }, this.ERROR_STRATEGIES.MODAL);
      },

      gotoDeleteAccount: function gotoDeleteAccount() {
        this.transitionToRoute("delete_account");
      }
    }
  });
});