define("goodcity/routes/item/messages", ["exports", "shared-goodcity/routes/messages_base", "ember"], function (exports, _sharedGoodcityRoutesMessages_base, _ember) {
  exports["default"] = _sharedGoodcityRoutesMessages_base["default"].extend({
    model: function model() {
      return _ember["default"].RSVP.Promise.all([this.store.query("version", {
        for_item: "true",
        item_id: this.modelFor("item").get("id")
      })]);
    },

    setupController: function setupController(controller, model) {
      this._super(controller, model);
      controller.set("item", this.modelFor("item"));
    }
  });
});