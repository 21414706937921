define('goodcity/routes/offer/donation_details', ['exports', 'goodcity/routes/authorize'], function (exports, _goodcityRoutesAuthorize) {
  exports['default'] = _goodcityRoutesAuthorize['default'].extend({

    model: function model() {
      if (this.modelFor('offer')) {
        var offerId = this.modelFor('offer').get('id');
        return this.store.peekRecord('offer', offerId);
      }
    }
  });
});