define('goodcity/routes/offer/index', ['exports', 'goodcity/routes/authorize'], function (exports, _goodcityRoutesAuthorize) {
  exports['default'] = _goodcityRoutesAuthorize['default'].extend({
    controllerName: 'offer/offer_details',

    afterModel: function afterModel(my_offer) {
      if (!my_offer) {
        this.transitionTo('offers');
      } else if (my_offer && my_offer.get('itemCount') > 0) {
        this.transitionTo('offer.offer_details', my_offer);
      }
    }
  });
});