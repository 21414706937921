define('goodcity/components/joyride-foundation', ['exports', 'ember', 'goodcity/computed/local-storage'], function (exports, _ember, _goodcityComputedLocalStorage) {
  exports['default'] = _ember['default'].Component.extend({
    store: _ember['default'].inject.service(),

    currentUserId: _ember['default'].computed(function () {
      return this.get("session.currentUser.id");
    }).volatile(),

    isTourSeen: _ember['default'].computed(function () {
      return this.get("session.seenTour") === this.get("currentUserId");
    }).volatile(),

    didInsertElement: function didInsertElement() {
      this._super();
      var _this = this;

      var offerCount = this.get("store").peekAll("offer").get("length");
      var itemCount = this.get("store").peekAll("item").get("length");
      var recentlyCreated = new Date() - this.get("currentController.model.createdAt") <= 12 * 60 * 60 * 1000; // 12 hrs
      var isDraft = this.get("currentController.model.isDraft");
      var firstEverItem = offerCount === 1 && itemCount === 1 && recentlyCreated && isDraft;

      if (firstEverItem && !this.get("isTourSeen")) {
        _ember['default'].$().ready(function () {
          _ember['default'].$(document).foundation({
            joyride: {
              modal: true,
              nub_position: 'top',
              tip_animation: 'pop',
              tip_location: 'bottom',

              pre_ride_callback: function pre_ride_callback() {
                showJoyrideOverlay();
              },

              post_ride_callback: function post_ride_callback() {
                hideJoyrideOverlay();
                setSeenTour();
              }
            }
          }).foundation('joyride', 'start');

          _ember['default'].$(".joyride-close-tip").click(function () {
            hideJoyrideOverlay();
          });

          function hideJoyrideOverlay() {
            _ember['default'].$(".joyride-view-background").hide();
          }

          function showJoyrideOverlay() {
            _ember['default'].$(".joyride-view-background").show();
          }

          function setSeenTour() {
            _this.set("session.seenTour", _this.get("currentUserId"));
          }
        });
      }
    }
  });
});