define("goodcity/routes/offer/offer_details", ["exports", "goodcity/routes/authorize", "ember"], function (exports, _goodcityRoutesAuthorize, _ember) {
  exports["default"] = _goodcityRoutesAuthorize["default"].extend({
    model: function model() {
      if (this.modelFor("offer")) {
        var offerId = this.modelFor("offer").get("id");
        return this.store.peekRecord("offer", offerId);
      }
    },

    afterModel: function afterModel(my_offer) {
      _ember["default"].RSVP.Promise.all([this.store.query("message", {
        messageable_type: "Offer",
        messageable_id: my_offer.get("id")
      }), this.store.query("message", {
        messageable_type: "Item",
        messageable_id: my_offer.get("items").getEach("id")
      })]);

      if (!my_offer) {
        this.transitionTo("offers");
      } else if (my_offer && my_offer.get("itemCount") === 0) {
        this.transitionTo("offer", my_offer);
      }
    }
  });
});