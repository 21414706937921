define("goodcity/controllers/item/messages", ["exports", "ember", "shared-goodcity/controllers/messages_base"], function (exports, _ember, _sharedGoodcityControllersMessages_base) {
  var getOwner = _ember["default"].getOwner;
  exports["default"] = _sharedGoodcityControllersMessages_base["default"].extend({
    item: null,
    noMessage: _ember["default"].computed.empty("messages"),
    offerDetailsController: _ember["default"].inject.controller("offer/offer_details"),

    displayChatNote: _ember["default"].computed("noMessage", "disabled", function () {
      return this.get("noMessage") && !this.get("disabled");
    }),

    messageBox: _ember["default"].inject.service(),
    i18n: _ember["default"].inject.service(),

    cancelByDonor: _ember["default"].computed("item", {
      get: function get() {
        return false;
      },
      set: function set(key, value) {
        return value;
      }
    }),

    isItemVanished: _ember["default"].computed.or("item.isDeleted", "item.isDeleting"),

    showDeleteError: _ember["default"].observer("item", "isItemVanished", function () {
      var _this = this;

      var currentPath = window.location.href;

      if (this.get("isItemVanished") && !this.get("cancelByDonor")) {
        if (currentPath.indexOf("items/" + this.get("item.id")) >= 0) {
          this.get("messageBox").alert(this.get("i18n").t("404_error"), function () {
            return _this.transitionToRoute("offers");
          });
        }
      }
    }),

    actions: {
      removeItem: function removeItem(item) {
        var _this2 = this;

        var controller = this;
        var offer = item.get("offer");

        if (offer.get("state") !== "draft" && offer.get("items").rejectBy("isDrafted").length <= 1) {
          this.get("messageBox").custom(this.get("i18n").t("item.cancel_last_item_confirm"), this.get("i18n").t("not_now"), null, this.get("i18n").t("item.cancel"), function () {
            return _this2.get("offerDetailsController").send("cancelOffer", offer, true);
          });
          return;
        }

        this.get("messageBox").custom(this.get("i18n").t("delete_confirm"), this.get("i18n").t("not_now"), null, this.get("i18n").t("item.cancel"), function () {
          _this2.set("cancelByDonor", true);
          var loadingView = getOwner(controller).lookup("component:loading").append();

          offer.get("items").removeObject(item);

          item.destroyRecord().then(function () {
            if (offer.get("itemCount") === 0) {
              controller.transitionToRoute("offer");
            } else {
              controller.transitionToRoute("offer.offer_details");
            }
          })["finally"](function () {
            loadingView.destroy();
            _this2.set("cancelByDonor", false);
          });
        });
      }
    }
  });
});