define("goodcity/controllers/offer/offer_details", ["exports", "ember", "goodcity/computed/local-storage", "goodcity/utils/records", "goodcity/controllers/item/edit_images"], function (exports, _ember, _goodcityComputedLocalStorage, _goodcityUtilsRecords, _goodcityControllersItemEdit_images) {
  var getOwner = _ember["default"].getOwner;
  exports["default"] = _goodcityControllersItemEdit_images["default"].extend({
    items: _ember["default"].computed.alias("model.items"),
    sortProperties: ["latestUpdatedTime:desc"],
    sortedItems: _ember["default"].computed.sort("offerAndItems", "sortProperties"),
    i18n: _ember["default"].inject.service(),
    messageBox: _ember["default"].inject.service(),

    cancelByDonor: _ember["default"].computed("model", {
      get: function get() {
        return false;
      },
      set: function set(key, value) {
        return value;
      }
    }),

    isOfferVanished: _ember["default"].computed.or("model.isDeleted", "model.isDeleting"),

    showDeleteError: _ember["default"].observer("model", "isOfferVanished", function () {
      var _this = this;

      var currentPath = window.location.href;

      if (this.get("isOfferVanished") && !this.get("cancelByDonor")) {
        if (currentPath.indexOf("offers/" + this.get("model.id")) >= 0 && currentPath.indexOf("items") < 0) {
          this.get("messageBox").alert(this.get("i18n").t("404_error"), function () {
            return _this.transitionToRoute("offers");
          });
        }
      }
    }),

    hasActiveGGVOrder: _ember["default"].computed.alias("model.delivery.gogovanOrder.isActive"),

    offerAndItems: _ember["default"].computed("model", "items.@each.state", function () {
      // avoid deleted-items which are not persisted yet.
      var elements = this.get("items").rejectBy("state", "draft").rejectBy("isDeleted", true).toArray();

      // add offer to array for general messages display
      elements.push(this.get("model"));
      return elements;
    }),

    offers: _ember["default"].computed(function () {
      return this.store.peekAll("offer");
    }),

    actions: {
      addItem: function addItem() {
        var _this2 = this;

        var message;
        if (this.get("model.isScheduled")) {
          if (this.get("model.hasCrossroadsTransport")) {
            message = this.get("i18n").t("offer.offer_details.crossroads_booking_alert");
          } else {
            message = this.get("i18n").t("offer.offer_details.ggv_booking_alert");
          }

          this.get("messageBox").confirm(message, function () {
            _this2.send("allowAddItem");
          });
        } else {
          this.send("allowAddItem");
        }
      },

      allowAddItem: function allowAddItem() {
        var draftItemId = this.get("model.items").filterBy("state", "draft").get("firstObject.id");
        if (draftItemId) {
          this.transitionToRoute("item.edit", draftItemId);
        } else {
          this.send("triggerUpload");
        }
      },

      deleteOffer: function deleteOffer(offer) {
        var _this3 = this;

        this.set("cancelByDonor", true);
        var loadingView = getOwner(this).lookup("component:loading").append();

        offer = this.get("store").peekRecord("offer", offer.get("id"));
        this.get("store").deleteRecord(offer);
        offer.save().then(function () {
          _goodcityUtilsRecords["default"].unloadRecordTree(offer);
          _this3.transitionToRoute("offers.index");
        })["catch"](function (error) {
          offer.rollbackAttributes();
          throw error;
        })["finally"](function () {
          loadingView.destroy();
          _this3.set("cancelByDonor", false);
        });
      },

      cancelOffer: function cancelOffer(offer, alreadyConfirmed) {
        var _this4 = this;

        if (this.get("hasActiveGGVOrder")) {
          this.transitionToRoute("offer.cancel", offer);
        } else if (alreadyConfirmed) {
          this.send("deleteOffer", offer);
        } else {
          this.get("messageBox").custom(this.get("i18n").t("delete_confirm"), this.get("i18n").t("not_now"), null, this.get("i18n").t("offer.index.cancel"), function () {
            _this4.send("deleteOffer", offer);
          });
        }
      },

      addMoreItem: function addMoreItem() {
        if (!this.get("model.preventNewItem")) {
          this.send("addItem");
        }
      },

      handleBrokenImage: function handleBrokenImage() {
        this.get("model.reviewedBy").set("hasImage", null);
      }
    }
  });
});