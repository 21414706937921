define("goodcity/controllers/faq", ["exports", "ember"], function (exports, _ember) {
  exports["default"] = _ember["default"].Controller.extend({
    questionAnchor: null,

    actions: {
      displayQuestion: function displayQuestion() {
        var question = this.get("questionAnchor");
        if (question) {
          var scrollOffset = _ember["default"].$("#" + question).offset().top;

          _ember["default"].$('html, body').animate({
            scrollTop: scrollOffset - 70
          }, 'fast');
        }
        return false;
      }
    }
  });
});