define('goodcity/components/new-offer-link', ['exports', 'ember'], function (exports, _ember) {
  var getOwner = _ember['default'].getOwner;
  exports['default'] = _ember['default'].Component.extend({
    store: _ember['default'].inject.service('store'),
    messageBox: _ember['default'].inject.service(),
    i18n: _ember['default'].inject.service(),

    allOffers: _ember['default'].computed(function () {
      return this.get('store').peekAll("offer");
    }),

    existingOffer: _ember['default'].computed('offers.@each.state', function () {
      var offers = this.get("allOffers");
      return offers.filterBy("isUnderReview", true).get("firstObject") || offers.filterBy("isReviewed", true).get("firstObject") || offers.filterBy("isSubmitted", true).get("firstObject");
    }),

    locale: function locale(str) {
      return this.get("i18n").t(str);
    },

    actions: {
      addNewOffer: function addNewOffer() {
        this.get("router").transitionTo("offers.new");
      },

      addNewItem: function addNewItem() {
        var _this = this;

        var store = this.get('store');
        var loadingView = getOwner(this).lookup('component:loading').append();
        var offer = this.get("existingOffer");
        var defaultDonorCondition = store.peekAll("donorCondition").sortBy("id").get("firstObject");

        var draft_item = offer.get("items").filterBy("isDrafted", true).get("firstObject");

        var item = draft_item || store.createRecord("item", {
          offer: offer,
          donorCondition: defaultDonorCondition,
          state: "draft"
        });

        item.save().then(function () {
          loadingView.destroy();
          _this.get("router").transitionTo("item.edit_images", offer, item);
        });
      },

      confirmNewOffer: function confirmNewOffer() {
        var _this2 = this;

        if (this.get("existingOffer")) {
          this.get("messageBox").custom(this.locale("offers.index.new_offer_message"), this.locale("offers.index.new_offer"), function () {
            return _this2.send("addNewOffer");
          }, this.locale("offers.index.add_item"), function () {
            return _this2.send("addNewItem");
          }, true);
        } else {
          this.send("addNewOffer");
        }
      }
    }
  });
});