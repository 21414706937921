define("goodcity/controllers/delete_account", ["exports", "ember", "goodcity/mixins/async", "goodcity/utils/ajax-promise"], function (exports, _ember, _goodcityMixinsAsync, _goodcityUtilsAjaxPromise) {
  exports["default"] = _ember["default"].Controller.extend(_goodcityMixinsAsync["default"], {
    user: _ember["default"].computed.alias("model.user"),
    application: _ember["default"].inject.controller(),

    offers: _ember["default"].computed(function () {
      return this.store.peekAll("offer");
    }),

    activeOffersCount: _ember["default"].computed("offers.@each.state", function () {
      var inactiveStates = ["draft", "cancelled", "closed", "inactive"];
      var filteredOffers = this.get("offers").filter(function (offer) {
        return !inactiveStates.includes(offer.get("state"));
      });
      var count = filteredOffers.length;
      return count;
    }),

    canDeleteAccount: _ember["default"].computed("activeOffersCount", function () {
      return this.get("activeOffersCount") === 0;
    }),

    actions: {
      viewOffers: function viewOffers() {
        this.transitionToRoute("offers");
      },

      cancelAccountDeletion: function cancelAccountDeletion() {
        this.transitionToRoute("account");
      },

      confirmDeleteAccount: function confirmDeleteAccount() {
        var _this = this;

        this.runTask(function () {
          var userId = _this.get("user.id");
          var data = new _goodcityUtilsAjaxPromise["default"]("/users/" + userId, "DELETE", _this.get("session.authToken"));
          _this.get("application").send("logMeOut");
        }, this.ERROR_STRATEGIES.MODAL);
      }
    }
  });
});