define('goodcity/models/package_type', ['exports', 'ember-data'], function (exports, _emberData) {

  var attr = _emberData['default'].attr,
      hasMany = _emberData['default'].hasMany;

  exports['default'] = _emberData['default'].Model.extend({
    name: attr('string'),
    code: attr('string'),
    isItemTypeNode: attr('boolean', { defaultValue: false }),

    items: hasMany('item', { async: false }),
    packages: hasMany('package', { async: false })
  });
});