define("goodcity/locales/zh-tw/translations", ["exports", "ember", "shared-goodcity/locales/zh-tw/translations"], function (exports, _ember, _sharedGoodcityLocalesZhTwTranslations) {
  var I18nTranslationsZhTw;

  I18nTranslationsZhTw = _ember["default"].$.extend(true, _sharedGoodcityLocalesZhTwTranslations["default"], {
    "socket_offline_error": "正在嘗試連線…",
    "QuotaExceededError": "如使用Safari <b>私密瀏覽</b>，或不能瀏覽本網站。請嘗試</br><ul><li><a href='https://itunes.apple.com/in/app/goodcity.hk/id1012253845?mt=8' style='color: black!important; background-color: #dee4eb !important;'>下載iOS應用程式</a></li><li>使用Safari一般（非私密瀏覽）模式</li><li>使用Chrome私密瀏覽模式</li></ul>",
    "not_now": "暫不決定",
    "unexpected_error": "Unexpected Error",
    "confirm": "Confirm",
    "menu": {
      "support_gc": "支持好人好市！",
      "faq": "常見問題",
      "rate": "在應用程式商店評分"
    },
    "application": {
      "app_menu": "目錄",
      "my_offers": "我的捐獻",
      "my_account": "管理賬戶"
    },
    "support": {
      "title": "非常感謝！",
      "rely_on_donation": "好人好市有賴各界慷慨捐助才得以運作，改善貧困人士的生命。",
      "give_secure": "立即捐款，我們確保捐款過程保險穩妥。",
      "donation_done_on": "閣下於 {{date}} 捐款港幣 {{amount}}。",
      "donate_again": "閣下可以儲存付款資料，加快下次捐款過程，或在下面更改付款方法。",
      "about_goodcity": "好人好市由十字路會發起，十字路會是香港已註冊豁免稅項的非牟利機構。",
      "other_methods": "閣下可選擇其他捐款方法",
      "via_crossroads": "通過十字路會",
      "contact_us": "如遇下列情況，請即聯絡我們：",
      "need_receipt": "（如捐款超過港幣100元）閣下需要扣稅收據。",
      "know_cost": "查詢好人好市營運經費的詳情。",
      "wish_for_sponsor": "成為主要贊助人。",
      "amount": "港幣數目",
      "proceed": "繼續",
      "thanks": "謝謝！閣下的捐款已經處理完畢。",
      "display_error": "閣下的捐款出現問題，須要跟進：",
      "error": "付款服務出現問題，請稍後再試。"
    },
    "tour": {
      "step1.title": "1. 圖片上傳及說明",
      "quality_items": "物資狀況",
      "we_receive": "我們收到的物資",
      "step2.title": "2. 即時審查",
      "chat": "假如我們有任何疑問",
      "questions": "我們可以再詳談",
      "step3.title": "3. 運輸安排",
      "accepted_items": "已接受的物資：",
      "quick_easy": "簡易又快捷",
      "step4.title": "4. 幫助到有需要的人",
      "make_goodcity": "怎樣令這個城市變得更美好？",
      "faq": "常見問題"
    },
    "register": {
      "hk_only": "電話號碼 # （只限香港）",
      "given_name": "名字",
      "family_name": "姓氏",
      "districts": "地區",
      "register": "登記",
      "login": "登入",
      "fill_all_fields": "請填妥所有欄目",
      "use_of_personal_info": "我們如何使用你的個人資料",
      "phone_number": "電話號碼",
      "john": "無名",
      "doe": "氏",
      "agree_toc": "如使用好人好市服務，則表示閣下同意以下",
      "terms": "使用條款"
    },
    "offers": {
      "index": {
        "new_donation": "新的捐獻",
        "my_offers": "我的捐獻",
        "total_items": "物品總數： {{itemCount}}",
        "see_more": "更多...",
        "unread_messages": "未讀信息： {{unreadMessagesCount}}",
        "complete_offer": "完成此項捐獻",
        "in_review": "正在審查",
        "awaiting_review": "等候審查",
        "arrange_transport": "請安排運輸",
        "closed": "已完成",
        "received": "已收到",
        "receiving": "正在接收",
        "van_booked": "已預約貨車",
        "van_confirmed": "已確認貨車安排",
        "picked_up": "已經提取",
        "drop_off": "親身送到十字路會",
        "alternate": "已預約十字路會收集站",
        "awaiting_driver": "正在等候司機的資料",
        "driver_name": "司機姓名",
        "phone": "電話",
        "vehicle": "車輛",
        "approved": "已接受的物品",
        "rejected": "不接受的物品",
        "donations": "捐獻",
        "inactive": "暫停：可以再次提交本捐獻項目",
        "add_item": "加入項目",
        "new_offer": "新捐獻項目",
        "new_offer_message": "其中一項捐獻仍在處理中，請問閣下希望將新物資列為新捐獻項目，還是加入至現存捐獻項目？"
      }
    },
    "offer": {
      "camera": "照相機",
      "delete": "刪除",
      "messages": "信息",
      "details": "捐獻細節",
      "no_items": "此類別還沒有任何捐獻。快作出您第一次的捐獻吧！",
      "confirm": {
        "heading": "確認",
        "notice": "由於儲存空間有限，社區的需要亦經常改變，<br> 我們可能無法接收部分物資，非常抱歉！ <br>",
        "review": "我們專業的義工團隊 <br> 會馬上審查您捐獻的物資",
        "thank": "非常感謝！",
        "next": "確認！下一步"
      },
      "submit": {
        "heading": "物資出售",
        "message": "有時候，要幫助窮困人士， <br> 最好的方法就是出售捐出的物品。 <br> 您是否同意出售物品？ <br> "
      },
      "notifications": {
        "title": "信息",
        "alert_updates": "當我們有問題或更新捐獻項目的時候，閣下願意收到通知嗎？",
        "no": "不用了，謝謝。",
        "notify": "通知我",
        "change_ios_settings": "變更設定，這樣當我們向閣下提問或更新捐款狀態時，你將收到提示。",
        "open_settings": "1.到設定頁面。",
        "tap_notifications": "2.點擊提示。",
        "set_notification_on": "3.將「接收提示」一欄改成「開」。",
        "open_settings_button": "打開設定",
        "close": "關閉"
      },
      "index": {
        "item_count": "捐出物品 ({{itemCount}}) 件",
        "add_item": "加入物品",
        "add_items": "捐出另一件物品",
        "confirm": "完成，下一步",
        "review": "審查狀況",
        "cancel": "取消捐獻",
        "description": "說明",
        "condition": "物資狀況"
      },
      "offer_details": {
        "heading": "捐獻     細節",
        "submitted_status": "我們正在審查您捐出的物品",
        "in_review_status": "您捐出的物品正由 {{firstName}}審查",
        "is_collection": "收集站",
        "is_drop_off": "接收地點",
        "is_gogovan_order": "已預約貨車",
        "driver_completed": "司機已確認預約",
        "is_gogovan_confirm": "已確認貨車安排",
        "reviewed_message": "審查已完成！",
        "please": "請",
        "transport": "安排運輸",
        "offer_messages": "一般信息",
        "accepted": "已接納",
        "not_needed": "不需要",
        "closed_offer_message": "此項捐獻已結束。不再需要此類物資，請見諒。",
        "offer_received": "已收到捐獻",
        "offer_start_receiving": "{{firstName}} {{lastName}} 開始接收物資",
        "crossroads_booking_alert": "我們會盡快處理新增的物品。我們的收集團隊通常只會接收已被審查及接受的物資。<br><br>如我們接受了大型物件，我們可能會取消及重新安排運輸。",
        "ggv_booking_alert": "我們會盡快處理新的物品，請不要送出未被接受的物資。<br><br>如果你需要更改運輸安排以容納更多的物品，請按運送詳情。"
      },
      "transport_details": {
        "heading": "運送    詳情",
        "no_items": "此項捐獻 <br> 並沒有需要運送的物資",
        "arrange_transport": "安排運輸",
        "wait_for_transport": "審查完成後， <br> 隨即可以安排運送物資",
        "accepted_items_count": "目前已接納的物資數目為 ({{acceptedCount}})",
        "goods_received": "收到物資的日期",
        "thank": "非常感謝",
        "receiving": "我們正在接收你捐獻的物資。"
      },
      "display_joyride": {
        "camera": "點選 <i class='fa fa-camera'></i> 加入更多項目",
        "delete": "點選 <i class='fa fa-trash'></i> 取消捐獻",
        "all_done_next": "加入最後一項物品後，請繼續下一步",
        "first_item": "點選任何物品以檢閱或修改資料"
      },
      "cancel": {
        "title": "取消捐獻",
        "confirm_ggv_cancel": "GoGoVan確認預約取消後，您可以取消是次捐獻",
        "cancel_offer": "取消捐獻"
      }
    },
    "item": {
      "item_details": "物資細節",
      "donor_description": "描述： {{donorDescription}}",
      "condition": "狀況： {{condition}}",
      "cancel": "刪除項目",
      "edit": "修改項目",
      "submitted_status": "此項物品正在等候審查",
      "in_review_status": "此項物品的審查已經完成",
      "accepted_status": "此項物品已被接納",
      "rejected_status": "此項物品不被接納",
      "cancel_last_item_confirm": "取消最後一項物品即會取消此項捐獻，您是否確定？",
      "cancelled_status": "這項捐贈已被 {{lastName}} {{firstName}} 取消。",
      "messages": {
        "info_text1": "審查物品時，如果有任何問題，我們會在下面的對話框向您查詢",
        "info_text2": "如您希望加添任何對捐獻物品的描述，請在此留言"
      }
    },
    "account": {
      "edit": "編輯",
      "delete_account": "刪除賬戶",
      "discard_changes": "取消",
      "save_changes": "保存改動",
      "user_fields": {
        "title": "稱謂",
        "firstName": "名",
        "lastName": "姓",
        "mobile": "手提電話",
        "email": "電郵",
        "district": "地區 "
      }
    },
    "delete_account": {
      "title": "刪除賬戶 ",
      "section1": "刪除你的賬戶後，你將無法再登入GoodCity.HK",
      "section2": "如若將來你想再使用服務，歡迎你重新建立新賬戶。 ",
      "section3": "請注意你的部分資料可能會被保留，以符合的合規和審計要求。",
      "section4": "所有資料將按照我們的政策處理。",
      "section5": "如若按下面的按鈕，你將會立即登出GoodCity.HK的服務。",
      "yes_delete": "是的，請刪除我的賬戶",
      "cancel": "取消 ",
      "goback": "返回",
      "view_offers": "瀏覽捐贈的物資 ",
      "sorry1": "抱歉！在捐贈物資的過程中，你恕未能刪除你的賬戶。 ",
      "sorry2": "當收妥你的物資，你的捐贈申請就算完成。",
      "sorry3": "如需更多幫助，請在捐贈物資過程中聯絡我們。",
      "crf_privacy_policy": "國際十字路會私隱政策 "
    },
    "faqs": {
      "title": "常見問題",
      "description": "「妥當配物資，快捷助我城」為好人好市之目標。",
      "questions": {
        "question1": "好人好市接收甚麼類型的物資？",
        "question2": "審查員根據甚麼標準接收物資？",
        "question3": "有哪幾種貨運方法？",
        "question4": "我可以邀請別人使用好人好市應用程式嗎？",
        "question5": "甚麼人受惠於這些物資呢？",
        "question6": "身在在海外也可以捐出物資嗎？",
        "question7": "為何選址香港？"
      },
      "answers": {
        "answer1": {
          "good_condition": "我們重視物資的狀況是否良好，以示對慈善團體及受惠人士的尊重。",
          "useful": "使用好人好市，只需幾分鐘的審查，您就能知道您捐出的物資是否能夠幫助別人。常見的物資有傢俱、家庭用品、電腦、電器等。"
        },
        "answer2": {
          "review": "我們的審查員會考慮當時的供求情況，並考慮不同物資可能帶來的限制，例如是否符合品質要求、大小限制、安全標準，運輸會否有困難，是否符合當地電壓、兼容、設計的要求等。",
          "messaging": "審查員會透過好人好市應用程式裏的通訊功能，查詢有關捐贈物資的資料。這樣，您就不用填寫複雜冗長的表格。大部分的情況中，只要有相片及簡短的描述就足夠了。",
          "supervisors": "審查員亦能夠即時知會專業的管理員，他們大多擁有五年或以上在香港處理捐贈物資的經驗。他們處理過很多罕見的物資捐獻，例如是商業用披薩烤箱、十萬件全新西裝、大量假花裝飾等。面對這些古怪又實用的物資，他們非常清楚該如何處理。"
        },
        "answer3": {
          "gogovan": "<b>租用貨車，快捷妥當：</b> 通過我們的應用程式，您能夠預約貨車，這是運送物資的最快方法。您直接付款給司機。",
          "drop_off": "<b>親自運送：</b> 通過我們的應用程式，您能夠預約時間，將物資親自送到我們位於屯門的辦公室。"
        },
        "answer4": "社工及慈善機構員工能使用好人好市的特別版網站和手機程式，目前只有受邀機構和人士可以使用特別版程式，但我們希望最終能夠開放給香港所有的註冊社工及慈善機構使用。",
        "answer5": {
          "title": "我們幫助不同社群的需要：",
          "community": "社區/文化團體",
          "drug": "濫藥更新人士",
          "education": "教育機構",
          "elderly": "長者",
          "environmental": "環保團體",
          "families": "有需要的家庭",
          "medical_n_hospitals": "醫護診所及醫院",
          "orphanages": "孤兒院",
          "special_needs": "特殊需要人士",
          "risk": "高危人士",
          "rehabilitation": "復康服務",
          "service_orgs": "服務機構",
          "social_enterprise": "社會企業",
          "sports": "殘疾人士體育計劃",
          "suicide_prevention": "防止自殺服務",
          "domestic_workers": "家傭",
          "new_arrivals": "新移民",
          "women": "婦女",
          "vocational_training": "職業培訓計劃",
          "animals_welfare": "動物福利"
        },
        "answer6": "不可以。但如果您以公司身份從海外捐出大量物資，您就能夠使用我們的另一服務：",
        "global_hand": "環球援手",
        "answer7": {
          "hk": "香港的地理位置非常獨特，非常適合處理慈善物資捐獻，是全世界最有效率的地方，因為：人口密度高，相對富裕，智能電話極普及，慈善工作井然有序，公民意識亦逐漸成型。",
          "crossroads": "十字路會自1995年開始於香港處理各界捐贈的物資，至今發展出各種網上服務（如business.un.org）。這讓我們成為一個獨特的機構，擔當着十字路口的角色，聯繫捐贈物資的供求。"
        }
      }
    }
  });

  exports["default"] = I18nTranslationsZhTw;
});