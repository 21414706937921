define("goodcity/instance-initializers/ajax", ["exports", "goodcity/utils/ajax-promise"], function (exports, _goodcityUtilsAjaxPromise) {
  exports["default"] = {
    name: "ajax",
    initialize: function initialize(app) {
      var _app$container = app.container;
      var container = _app$container === undefined ? app : _app$container;

      var adapter = container.lookup("adapter:application");

      _goodcityUtilsAjaxPromise["default"].setDefaultHeaders(function () {
        return adapter.get("headers");
      });
    }
  };
});