define("goodcity/routes/offer", ["exports", "goodcity/routes/authorize", "goodcity/utils/ajax-promise"], function (exports, _goodcityRoutesAuthorize, _goodcityUtilsAjaxPromise) {
  exports["default"] = _goodcityRoutesAuthorize["default"].extend({
    model: function model(params) {
      var _this = this;
      return new _goodcityUtilsAjaxPromise["default"]("/offers/" + params.offer_id, "GET", _this.get("session.authToken"), { exclude_messages: "true" }).then(function (data) {
        _this.store.pushPayload(data);
        return _this.store.peekRecord("offer", params.offer_id);
      });
    }
  });
});