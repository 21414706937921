define("goodcity/routes/account", ["exports", "ember", "goodcity/routes/authorize"], function (exports, _ember, _goodcityRoutesAuthorize) {
  exports["default"] = _goodcityRoutesAuthorize["default"].extend({
    session: _ember["default"].inject.service(),

    setupController: function setupController(controller, model) {
      this._super(controller, model);
      controller.set("isEditing", false);

      var user = this.get("session").get("loggedInUser");
      var mobile = user.get("mobile");

      if (!/^\+852/.test(mobile)) {
        //  @tofix hack, we should really be keeping the country code in order to save the records
        user.set("mobile", "+852" + mobile);
      }
    },

    model: function model() {
      return _ember["default"].RSVP.hash({
        user: this.store.peekRecord("user", this.session.get("currentUser.id"))
      });
    },

    renderTemplate: function renderTemplate() {
      this.render(); // default template

      if (this.get("session.isLoggedIn")) {
        this.render("appMenuList", {
          into: "account",
          outlet: "appMenuList",
          controller: "application"
        });
      }
    }
  });
});