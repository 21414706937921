define('goodcity/controllers/offer/submit', ['exports', 'ember'], function (exports, _ember) {
  var getOwner = _ember['default'].getOwner;
  exports['default'] = _ember['default'].Controller.extend({

    cordova: _ember['default'].inject.service(),
    appReview: _ember['default'].inject.service(),

    actions: {
      submitOffer: function submitOffer(saleable) {
        var _this = this;

        var loadingView = getOwner(this).lookup('component:loading').append();
        var offer = this.get("model");
        offer.setProperties({ state_event: 'submit', saleable: saleable });

        offer.save().then(function () {
          return _this.transitionToRoute('offer.offer_details');
        })['finally'](function () {
          loadingView.destroy();
          _this.get("appReview").promptReviewModal();
        });
      },

      backLink: function backLink() {
        var _this2 = this;

        if (this.get("cordova").isIOS()) {

          var onEnabled = function onEnabled() {
            _this2.transitionToRoute('offer.confirm');
          };

          var onDisabled = function onDisabled() {
            _this2.transitionToRoute('offer.ios_notification');
          };

          this.get("cordova").verifyIosNotificationSetting(onEnabled, onDisabled);
        } else {
          this.transitionToRoute('offer.confirm');
        }
      }
    }
  });
});