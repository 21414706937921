define("goodcity/routes/authenticate", ["exports", "ember"], function (exports, _ember) {
  var getOwner = _ember["default"].getOwner;
  exports["default"] = _ember["default"].Route.extend({
    beforeModel: function beforeModel() {
      if (this.session.get("isLoggedIn")) {
        if (this.get("session.currentUser")) {
          this.transitionTo("/offers");
        } else {
          getOwner(this).lookup("route:application")._loadDataStore();
        }
      }
    }
  });
});