define('goodcity/components/faq-list', ['exports', 'ember', 'goodcity/components/init-foundation'], function (exports, _ember, _goodcityComponentsInitFoundation) {
  exports['default'] = _goodcityComponentsInitFoundation['default'].extend({

    didInsertElement: function didInsertElement() {
      this._super();
      var topMargin = this.get("session.isLoggedIn") ? 20 : 70;

      _ember['default'].run.scheduleOnce('afterRender', this, function () {
        var controller = this.get('currentController');
        if (controller && controller.questionAnchor) {
          controller.send('displayQuestion');
        }
      });

      _ember['default'].$().ready(function () {

        _ember['default'].$('.faq_list ul').on('click', 'li a', function () {
          var anchor = _ember['default'].$(this);
          var scrollOffset = _ember['default'].$(anchor.attr('href').split('faq')[1]).offset().top;

          _ember['default'].$('html, body').animate({
            scrollTop: scrollOffset - topMargin
          }, 'fast');

          return false;
        });
      });
    }

  });
});