define("goodcity/models/user", ["exports", "ember", "ember-data", "goodcity/models/addressable"], function (exports, _ember, _emberData, _goodcityModelsAddressable) {

  var attr = _emberData["default"].attr,
      belongsTo = _emberData["default"].belongsTo,
      hasMany = _emberData["default"].hasMany;

  exports["default"] = _goodcityModelsAddressable["default"].extend({
    firstName: attr("string"),
    lastName: attr("string"),
    mobile: attr("string"),
    createdAt: attr("date"),
    userRoleIds: attr(""),
    email: attr(""),
    receiveEmail: attr(""),
    otherPhone: attr(""),
    title: attr(""),

    lastConnected: attr("date"),
    lastDisconnected: attr("date"),

    image: belongsTo("image", { async: false }),
    permission: belongsTo("permission", { async: true }),
    reviewedOffers: hasMany("offers", { inverse: "reviewedBy", async: false }),
    donations: hasMany("offers", { inverse: "createdBy", async: false }),

    userRoles: hasMany("userRoles", { async: false }),

    roles: _ember["default"].computed("userRoles.[]", function () {
      var roles = [];
      this.get("userRoles").forEach(function (userRole) {
        roles.push(userRole.get("role"));
      });
      return roles;
    }),

    roleIds: _ember["default"].computed("userRoles.[]", function () {
      return this.get("userRoles").getEach("roleId");
    }),

    i18n: _ember["default"].inject.service(),

    isSupervisor: _ember["default"].computed.equal("permission.name", "Supervisor"),

    nameInitial: _ember["default"].computed("firstName", function () {
      return this.get("firstName").charAt(0).capitalize();
    }),

    roleInitials: _ember["default"].computed("permission", function () {
      var permission = this.get("permission.name") || "Donor";
      return "(" + permission.capitalize().charAt(0) + ")";
    }),

    displayImageUrl: _ember["default"].computed("image", function () {
      return this.get("image.thumbImageUrl") || "assets/images/default_user_image.jpg";
    }),

    hasImage: _ember["default"].computed("image", {
      get: function get() {
        return this.get("image.thumbImageUrl");
      },
      set: function set(key, value) {
        return value;
      }
    }),

    fullName: _ember["default"].computed("firstName", "lastName", function () {
      return this.get("firstName") + " " + this.get("lastName");
    }),

    onlineStatus: _ember["default"].computed("lastConnected", "lastDisconnected", function () {
      if (!this.get("lastConnected") && !this.get("lastDisconnected")) {
        return this.get("i18n").t("not_connected");
      } else if (this.get("lastDisconnected") > this.get("lastConnected")) {
        return false;
      } else if (this.get("lastDisconnected") < this.get("lastConnected")) {
        return this.get("i18n").t("online");
      }
    })
  });
});