define("goodcity/routes/delete_account", ["exports", "ember", "goodcity/routes/authorize"], function (exports, _ember, _goodcityRoutesAuthorize) {
  exports["default"] = _goodcityRoutesAuthorize["default"].extend({
    session: _ember["default"].inject.service(),

    renderTemplate: function renderTemplate() {
      this.render(); // default template

      if (this.get("session.isLoggedIn")) {
        this.render("appMenuList", {
          into: "delete_account",
          outlet: "appMenuList",
          controller: "application"
        });
      }
    },

    model: function model() {
      return _ember["default"].RSVP.hash({
        user: this.store.peekRecord("user", this.session.get("currentUser.id"))
      });
    },

    // load offers into store to ensure our 'can delete' calculations work
    beforeModel: function beforeModel() {
      var cachedRecords = this.store.peekAll("offer");
      if (cachedRecords.get("length") === 0) {
        this.get("store").query("offer", { exclude_messages: "true" });
      }
    }
  });
});